var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-color":"rgba(0,0,0,0.4)","overlay-opacity":"0.8","max-width":"480","scrollable":"","persistent":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addCar)}}},[_c('v-card',{ref:"form",attrs:{"flat":""}},[_c('v-card-title',[_c('span',{staticClass:"ft font-size-md font-weight-bold blue-grey--text ml-1"},[_vm._v("Add New Vehicle")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"prmary"}},[_c('i',{staticClass:"material-icons"},[_vm._v("close")])])],1),_c('v-card-text',[_c('v-col',[_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-md",attrs:{"autofocus":"","label":"Vehicle's Registration Number","error-messages":errors[0]},model:{value:(_vm.car.registrationNumber),callback:function ($$v) {_vm.$set(_vm.car, "registrationNumber", $$v)},expression:"car.registrationNumber"}})]}}],null,true)})],1),_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-md",attrs:{"label":"Vehicle Model","error-messages":errors[0]},model:{value:(_vm.car.model),callback:function ($$v) {_vm.$set(_vm.car, "model", $$v)},expression:"car.model"}})]}}],null,true)})],1),_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-md",attrs:{"label":"Vehicle Brand","error-messages":errors[0]},model:{value:(_vm.car.brand),callback:function ($$v) {_vm.$set(_vm.car, "brand", $$v)},expression:"car.brand"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2 ft text-capitalize",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"ft white--text text-capitalize",attrs:{"color":"primary","type":"submit","loading":_vm.isSubLoading,"disabled":_vm.isSubLoading}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }