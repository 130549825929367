<template>
  <v-dialog
    overlay-color="rgba(0,0,0,0.4)"
    overlay-opacity="0.8"
    v-model="state"
    max-width="480"
    scrollable
    persistent
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(addCar)" class="px-3">
        <v-card flat ref="form">
          <v-card-title>
            <span class="ft font-size-md font-weight-bold blue-grey--text ml-1"
              >Add New Vehicle</span
            >
            <v-spacer></v-spacer>
            <v-btn icon color="prmary">
              <i class="material-icons">close</i>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-col>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    autofocus
                    v-model="car.registrationNumber"
                    class="ft font-weight-medium font-size-md"
                    label="Vehicle's Registration Number"
                    :error-messages="errors[0]"
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="car.model"
                    class="ft font-weight-medium font-size-md"
                    label="Vehicle Model"
                    :error-messages="errors[0]"
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="car.brand"
                    class="ft font-weight-medium font-size-md"
                    label="Vehicle Brand"
                    :error-messages="errors[0]"
                  />
                </ValidationProvider>
              </v-row>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="mr-2 ft text-capitalize" text @click="close">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              type="submit"
              :loading="isSubLoading"
              :disabled="isSubLoading"
              class="ft white--text text-capitalize"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
export default {
  name: "AddDriverAndAssignCar",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    ValidationObserver
  },
  props: {
    state: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    car: {
      registrationNumber: "",
      model: "",
      brand: ""
    }
  }),
  computed: {
    ...mapGetters({
      isSubLoading: "getIsSubLoading"
    })
  },
  watch: {
    isSubLoading(value) {
      if (!value) {
        this.car = { registrationNumber: null, model: null, brand: null };
      }
    }
  },
  methods: {
    close() {
      this.$emit("actions", "newCar");
    },
    addCar() {
      this.$emit("car", { ...this.car });
    }
  }
};
</script>
